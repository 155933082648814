html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, sans-serif;
  width: 100%;
  min-height: 100vh;

  color: #444444;
}

h1,p{
  margin: 0;
  padding: 13px 0;
}

* {
  box-sizing: border-box;
}

#__next{
  min-height: 100vh;
}
